<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>门店</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="门店名称">
                                <el-input v-model="search.store_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="负责人">
                                <el-input v-model="search.responsible"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="门店电话">
                                <el-input v-model="search.store_phone"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="城市">
                                <el-input v-model="search.city"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="发布状态">
                                <el-select v-model="search.publish_state" placeholder="请选择类型" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="已发布" :value=1></el-option>
                                    <el-option label="未发布" :value=0></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="审核状态">
                                <el-select v-model="search.examine_status" placeholder="请选择状态" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="审核中" :value="0"></el-option>
                                    <el-option label="审核通过" :value="1"></el-option>
                                    <el-option label="审核失败" :value="2"></el-option>
                                    <el-option label="违规下架" :value="3"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="门店类别">
                                <el-select v-model="search.merchant_category_id" placeholder="请选择门店类别" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option v-for="(item, index) in categoryData" :key="index" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="运动品类">
                                <el-input v-model="search.motion_category_two"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="创建时间">
                                <el-date-picker style="width: 100%" v-model="searchtime" type="daterange"
                                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                    format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="所属企业">
                                <el-input v-model="search.company_name"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <div style="display:flex;align-items: center;">
                        <h3 style="margin-right:10px">{{ page_name }}</h3>
                        <el-button size="medium" type="primary" @click="batchEditCompany('user')">
                            设置管理人员信息
                        </el-button>
                        <el-button v-if="is_auth('company.company.batchmerchantcategory')" size="medium" type="primary"
                            @click="batchEditCompany('category')">
                            设置门店类别
                        </el-button>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="is_auth('store.store.issave')" icon="el-icon-plus" size="medium" type="primary"
                            @click="tosee()">添加
                        </el-button>
                        <el-button icon="el-icon-download" size="medium" type="primary" @click="exportOrder">导出
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table size="medium" ref="companyTable" border v-loading="loading" :data="tableData" style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection">
                </el-table-column>
                <el-table-column prop="company_name" label="所属企业" width="200">
                </el-table-column>
                <el-table-column prop="store_name" label="门店名称" width="200">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.oss_arrangement === 1">平台可排课</el-tag>&nbsp;&nbsp;
                        <span>{{ scope.row.store_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="oss_user_name" label="商户运营" width="120">
                    <template slot-scope="scope">
                        <el-link v-if="is_auth('store.store.batchossuser')" style="width: 100%; text-align: center"
                            slot="reference" type="primary" @click="startEdit('user', scope.row)">
                            <p style="text-align:center;margin:0" v-if="scope.row.oss_user_name">
                                {{ scope.row.oss_user_name }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </el-link>
                        <div v-else>
                            <p style="text-align:center;margin:0" v-if="scope.row.oss_user_name">
                                {{ scope.row.oss_user_name }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="oss_user_mobile" label="商户运营联系方式" width="140">
                    <template slot-scope="scope">
                        <div>
                            <p style="text-align:center;margin:0" v-if="scope.row.oss_user_mobile">
                                {{ scope.row.oss_user_mobile }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="category_name" label="门店类别" width="120">
                    <template slot-scope="scope">
                        <el-link v-if="is_auth('company.company.batchmerchantcategory')"
                            style="width: 100%; text-align: center" slot="reference" type="primary"
                            @click="startEdit('category', scope.row)">
                            <p style="text-align:center;margin:0" v-if="scope.row.category_name">
                                {{ scope.row.category_name }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </el-link>
                        <div v-else>
                            <p style="text-align:center;margin:0" v-if="scope.row.category_name">
                                {{ scope.row.category_name }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="oss_merchant_uuid" label="运营商" width="120">
                    <template slot-scope="scope">
                        <el-link v-if="is_auth('store.store.batchossmerchant')" style="width: 100%; text-align: center"
                            slot="reference" type="primary" @click="startEdit('merchant', scope.row)">
                            <p style="text-align:center;margin:0" v-if="scope.row.oss_merchant_uuid">
                                {{ scope.row.oss_merchant_uuid }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </el-link>
                        <div v-else>
                            <p style="text-align:center;margin:0" v-if="scope.row.oss_merchant_uuid">
                                {{ scope.row.oss_merchant_uuid }}</p>
                            <p style="text-align:center;margin:0" v-else>/</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="qrcode" label="门店二维码" width="130">
                    <template slot-scope="scope">
                        <viewer v-if="!is_empty(scope.row.qrcode)">
                            <el-avatar shape="square" :size="22" :src="scope.row.qrcode"></el-avatar>
                        </viewer>
                        <div v-else>
                            <el-button v-if="is_auth('store.store.qrcode')" @click="get_qrcode(scope.row.store_uuid)"
                                type="primary" size="mini">门店二维码
                            </el-button>
                            <span v-else>-</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="examine_status" label="审核状态" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.examine_status === 0">审核中</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 1" type="success">审核通过</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 2" type="danger">审核失败</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_status === 3" type="danger">违规下架</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="publish_state" label="发布状态" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.publish_state === 0" type="warning">未发布</el-tag>
                        <el-tag size="mini" v-if="scope.row.publish_state === 1" type="success">已发布</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="publish_state" label="运动品类" min-width="200">
                    <template slot-scope="scope">
                        <el-tag :key="item.id" v-for="item in scope.row.motion_category_two" class="form-tag_colour-tag"
                            effect="dark" size="mini">
                            {{ item.name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="responsible" label="负责人" width="150">
                </el-table-column>
                <el-table-column prop="store_phone" label="门店电话" width="180">
                </el-table-column>
                <el-table-column prop="city" label="城市" width="150">
                </el-table-column>
                <el-table-column prop="address" label="地址" min-width="200">
                </el-table-column>
                <el-table-column prop="collect_sum" label="收藏数">
                    <template slot-scope="scope">
                        <span v-if="is_auth('store.store.optimize')">{{
                            scope.row.collect_sum
                        }}+{{ scope.row.optimize_collect_sum }}</span>
                        <span v-else>{{ scope.row.collect_sum + scope.row.optimize_collect_sum }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="see_sum" label="访问量">
                    <template slot-scope="scope">
                        <span v-if="is_auth('store.store.optimize')">{{ scope.row.see_sum }}+{{
                            scope.row.optimizesee
                        }}</span>
                        <span v-else>{{ scope.row.see_sum + scope.row.optimizesee }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" width="180">
                </el-table-column>
                <el-table-column prop="update_time" label="更新时间" width="180">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="410">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('store.store.getinfo')" @click="tosee(scope.row.store_uuid)" size="mini">查看
                        </el-button>
                        <el-button v-if="is_auth('store.store.settag')" @click="set_tag(scope.row)" type="primary"
                            size="mini">设置品类
                        </el-button>
                        <el-button v-if="is_auth('store.store.optimize')" size="mini" type="primary"
                            @click="visits(scope.row)">数据优化
                        </el-button>
                        <el-button v-if="is_auth('store.store.examine') && scope.row.examine_status === 0"
                            @click="operation_tip(scope.row.store_uuid, scope.row.store_name, 'examine', 1)" type="success"
                            size="mini">通过
                        </el-button>
                        <el-button v-if="is_auth('store.store.examine') && scope.row.examine_status === 0"
                            @click="operation_tip(scope.row.store_uuid, scope.row.store_name, 'examine', 2)" type="danger"
                            size="mini">不通过
                        </el-button>
                        <el-button
                            v-if="is_auth('store.store.examine') && scope.row.examine_status !== 0 && scope.row.examine_status !== 3"
                            @click="operation_tip(scope.row.store_uuid, scope.row.store_name, 'illegal', 3)" type="danger"
                            size="mini">违规下架
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
                layout="prev, pager, next, total, jumper" :current-page.sync="page" :total="count">
            </el-pagination>

            <!--设置品类-->
            <el-dialog v-if="is_auth('store.store.settag')" title="设置品类" :visible.sync="settagPage" width="50%"
                :close-on-press-escape="false" :close-on-click-modal="false">
                <el-row>
                    <el-form size="medium" ref="form" label-width="120px">
                        <el-col :span="24">
                            <el-form-item label="门店名称">
                                <span class="form-span-text">{{ tagdata.store_name }}</span>
                            </el-form-item>
                            <el-form-item label="运动品类">
                                <div>
                                    <MotionCategorySelect :motionCategoryList.sync="motion_category_two" />
                                </div>
                                <div class="form-tag_colour-div">
                                    <el-tag :key="item.index" v-for="item in this.tagdata.store_tag"
                                        class="form-tag_colour-tag" effect="dark" closable @close="tagClose(item)"
                                        :color="item.tag_colour">
                                        {{ item.tag_name }}
                                    </el-tag>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-row>
                <el-row style="margin: 10px 0px;">
                    <el-button v-if="is_auth('store.store.settag')" size="medium" type="primary" @click="saveCategoryData">确
                        定
                    </el-button>
                    <el-button size="medium" @click="settagPage = false">取 消</el-button>
                </el-row>
            </el-dialog>
            <!--数据优化-->
            <el-dialog v-if="is_auth('store.store.optimize')" title="数据优化" :visible.sync="visitsVisible" width="40%"
                :close-on-press-escape="false" :close-on-click-modal="false">
                <el-row>
                    <el-form size="medium" ref="form" label-width="120px">
                        <el-col :span="24">
                            <el-form-item label="门店名称">
                                <span class="form-span-text">{{ info.store_name }}</span>
                            </el-form-item>
                            <el-form-item label="当前收藏">
                                <span class="form-span-text">{{ info.collect_sum }}</span>
                            </el-form-item>
                            <el-form-item label="当前访问量">
                                <span class="form-span-text">{{ info.see_sum }}</span>
                            </el-form-item>
                            <el-form-item label="优化收藏量">
                                <el-input
                                    onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                                    type="number" v-model="info.optimize_collect_sum"></el-input>
                            </el-form-item>
                            <el-form-item label="优化访问量">
                                <el-input
                                    onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                                    type="number" v-model="info.optimizesee"></el-input>
                            </el-form-item>

                        </el-col>
                    </el-form>
                </el-row>
                <el-row style="margin: 10px 0px;">
                    <el-button size="medium" type="primary" @click="save">确
                        定
                    </el-button>
                    <el-button size="medium" @click="visitsVisible = false">取 消</el-button>
                </el-row>
            </el-dialog>
        </div>
        <!-- 单选修改 -->
        <SingleEdit :edit-type="'store'" :loading="editLoading" :visible="isEditModelShow" :type="editType"
            :choose-merchant="chooseMerchant" :is-auth-batchossuser="is_auth('store.store.batchossuser')"
            :oss_user_uuid="oss_user_uuid" :merchant_category_id="merchant_category_id"
            :availableuser-data="availableuserData" :category-data="categoryData" @clearState="clearState"
            @save="singleSave" @changeTemplate="changeTemplate" />
        <!-- 批量修改 -->
        <BatchEdit :edit-type="'store'" :loading="editLoading" :visible="isBatchEditModelShow" :type="editType"
            :choose-company="chooseStore" :is-auth-batchossuser="is_auth('store.store.batchossuser')"
            :oss_user_uuid="oss_user_uuid" :merchant_category_id="merchant_category_id"
            :availableuser-data="availableuserData" :category-data="categoryData" @clearState="clearState"
            @changeTemplate="changeTemplate" @batchSave="batchSave" />
    </div>
</template>
<script>
import SingleEdit from "./../../../components/merchantEdit/SingleEdit";
import BatchEdit from "./../../../components/merchantEdit/BatchEdit";
import MotionCategorySelect from '@/components/MotionCategorySelect'
export default {
    components: { SingleEdit, BatchEdit, MotionCategorySelect },
    data() {
        return {
            page_name: '门店',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            searchtime: null,   // 时间条件
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
            settagPage: false,
            // 标签数据
            tagdata: {
                store_name: '',
                store_tag: [],
            },
            tag_list: [],       // 标签列表
            select_tag: '',     // 选中的标签

            visitsVisible: false,
            info: {},

            isEditModelShow: false, // 单个修改么门店信息
            isBatchEditModelShow: false, // 批量修改门店信息
            editLoading: false, // 编辑框加载状态
            chooseStore: [], // 当前选中的门店信息
            editType: "", // 修改门店信息类型 merchant --> 修改运营商 user --> 修改商户运营
            chooseMerchant: null, // 当前选中的门店信息
            oss_user_uuid: "", // 选中的商户运营人员id
            merchant_category_id: 0, // 选中的商户类别id
            availableuserData: [], // 可用商户运营人员列表
            categoryData: [], // 可用门店类别列表
            motion_category_two: [],
        }
    },
    // 创建
    created() {
        this.init()
        this.gettag()
        this.getAvailableuser();
        this.getCategoryList();
    },
    // 安装
    mounted() {
    },
    methods: {
        // 操作
        get_qrcode(store_uuid = '') {
            let postdata = {
                api_name: "store.store.qrcode",
                token: this.Tool.get_l_cache('token'),
                store_uuid: store_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '小程序码获取成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 获取可用商户运营人员列表
        getAvailableuser() {
            let postdata = {
                api_name: "systemset.user.availableuser",
                token: this.Tool.get_l_cache('token'),
            };
            this.Tool.post_data('oss', postdata, (json) => {
                if (json.code === 0) {
                    this.availableuserData = json.data.list
                }
            })
        },
        // 获取可用门店类别
        getCategoryList() {
            let postdata = {
                api_name: "company.merchantcategory.getusablelist",
                token: this.Tool.get_l_cache('token'),
            };
            this.Tool.post_data('oss', postdata, (json) => {
                if (json.code === 0) {
                    this.categoryData = json.data;
                }
            })
        },
        // 判断是否为空
        is_empty(data) {
            return this.Tool.is_empty(data)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        visits(row = {}) {
            this.info = row
            this.visitsVisible = true
        },
        // 初始化
        init() {
            this.search = {
                store_name: '',
                responsible: '',
                store_phone: '',
                city: '',
                publish_state: '',
                motion_category_two: '',
                merchant_category_id: "",
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "store.store.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 判断时间条件
            if (this.searchtime !== null) {
                postdata.starttime = this.searchtime[0]
                postdata.endtime = this.searchtime[1]
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(store_uuid = '', title = '', operation = "", state) {
            let tip = "";
            let options = {};
            // 启用
            if (operation === "examine") {
                tip = "启用【" + title + "】？";
                if (state === 1) {
                    tip = '审批通过【' + title + '】？';
                    // 弹出二次确认
                    this.$confirm(tip, "确认信息", options).then(() => this.isoperation(store_uuid, operation, state))
                } else {
                    tip = '不通过【' + title + '】？';
                    // 弹出二次确认
                    this.$prompt("请输入不通过理由", tip).then(({ value }) => this.isoperation(store_uuid, value, state))
                }
            }
            if (operation === "illegal") {
                tip = "违规下架【" + title + "】？";
                // 弹出二次确认
                this.$prompt("请输入违规下架原因", tip).then(({ value }) => this.isoperation(store_uuid, value, state))
            }
        },
        // 操作
        isoperation(store_uuid = '', value, state) {
            let postdata = {
                api_name: "store.store.examine",
                token: this.Tool.get_l_cache('token'),
                store_uuid,
                examine_reason: value,
                examine_status: state
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 查看
        tosee(store_uuid = '') {
            if (store_uuid === '') {
                this.$router.push({ path: '/store/store/add' })
            } else {
                this.$router.push({ path: '/store/store/details', query: { store_uuid: store_uuid } })
            }
        },
        // 获取标签
        gettag() {
            let postdata = {
                api_name: 'store.store.gettag',
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tag_list = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 设置标签
        set_tag(data) {
            console.log('data', data)
            this.tagdata = JSON.parse(JSON.stringify(data))
            this.motion_category_two = [...this.tagdata.motion_category_two]
            this.settagPage = true

        },
        // 添加标签
        add_tag() {
            let select_tag = this.select_tag.split("|")
            let tag = {
                tag_name: select_tag[0],
                tag_colour: select_tag[1],
            }
            if (!this.tag_exist(tag.tag_name)) {
                this.tagdata.store_tag.push(tag)
            }
            this.select_tag = ''
        },
        // 判断标签是否存在
        tag_exist(tag_name) {
            if (this.tagdata.store_tag.length > 0) {
                for (let v of this.tagdata.store_tag) {
                    if (tag_name == v.tag_name) {
                        return true
                    }
                }
                return false
            } else {
                return false
            }
        },
        // 删除标签
        tagClose(tag) {
            // console.log('tag', tag);
            // console.log('tag2', this.tagdata.store_tag);
            this.tagdata.store_tag.splice(this.tagdata.store_tag.indexOf(tag), 1);
        },
        // 保存标签
        issavetag() {
            let postdata = {
                api_name: "store.store.settag",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.tagdata);

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                            this.settagPage = false     // 关闭窗口
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        save() {
            let postdata = {
                api_name: "store.store.optimize",
                token: this.Tool.get_l_cache('token'),
                store_uuid: this.info.store_uuid,
                optimizesee: this.info.optimizesee,
                optimize_collect_sum: this.info.optimize_collect_sum,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            // this.page = 1
                            this.info = {}
                            this.visitsVisible = false
                            this.getlist()
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },

        // 获取选中的门店
        handleSelectionChange(e) { this.chooseStore = e },
        // 清楚多选的门店
        toggleSelection() { this.$refs.companyTable.clearSelection(); },
        // 清除数据
        clearState() {
            this.isEditModelShow = false;
            this.chooseMerchant = null;
            this.isBatchEditModelShow = false;
            this.oss_user_uuid = "";
            this.merchant_category_id = 0;
            this.editType = "";
            this.chooseStore = [];
            this.toggleSelection();
        },
        startEdit(type, item) {
            if (item.oss_user_uuid) {
                this.oss_user_uuid = item.oss_user_uuid;
            }
            this.editType = type;
            this.isEditModelShow = true;
            this.chooseMerchant = item;
        },
        batchEditCompany(type) {
            const { chooseStore } = this;
            if (chooseStore.length === 0) {
                this.$message.warning("请选择需要修改的门店");
            } else {
                this.isBatchEditModelShow = true;
                this.editType = type;
            }
        },
        changeTemplate(e) {
            const { editType } = this;
            if (editType === 'user') {
                this.oss_user_uuid = e;
            } else {
                this.merchant_category_id = e;
            }
        },
        // 保存商户运营人员信息
        singleSave() {
            const { chooseMerchant } = this;
            this.editLoading = true;
            this.submitPost([chooseMerchant.store_uuid]);
        },
        // 批量修改
        batchSave() {
            const { chooseStore, oss_user_uuid, editType, merchant_category_id } = this;
            let store_uuids = [];
            if (editType === 'category') {
                if (merchant_category_id === 0) {
                    this.$message.warning("请选择需要替换的商户类别");
                } else {
                    chooseStore.map(item => store_uuids.push(item.store_uuid));
                    this.submitPost(store_uuids);
                }
            } else {
                if (!oss_user_uuid) {
                    this.$message.warning("请选择需要替换的商户运营人员");
                } else {
                    chooseStore.map(item => store_uuids.push(item.store_uuid));
                    this.submitPost(store_uuids);
                }
            }
        },
        submitPost(store_uuids) {
            const { oss_user_uuid, merchant_category_id, editType } = this;
            let postdata = {
                token: this.Tool.get_l_cache('token'),
                store_uuids,
            };
            if (editType === 'user') {
                postdata['api_name'] = 'store.store.batchossuser';
                if (oss_user_uuid) {
                    postdata['oss_user_uuid'] = oss_user_uuid;
                } else {
                    this.$message.warning("请选择需要设置的商户运营人员");
                    return false;
                }
            } else if (editType === 'category') {
                postdata['api_name'] = 'store.store.batchmerchantcategory';
                if (merchant_category_id) {
                    postdata['merchant_category_id'] = merchant_category_id;
                } else {
                    this.$message.warning("请选择需要设置的商户类别");
                    return false;
                }
            }
            this.Tool.post_data('oss', postdata, (json) => {
                this.editLoading = false;
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist();  // 刷新列表(重新搜索)
                            this.clearState();
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        saveCategoryData() {
            let postdata = {
                api_name: "store.store.setcategory",
                token: this.Tool.get_l_cache("token"),
                store_uuid: this.tagdata.store_uuid,
                motion_category_two: this.motion_category_two.map(item => item.id)
            };

            this.loading = true;
            this.Tool.post_data("oss", postdata, (json) => {
                this.loading = false;
                this.getlist(); // 刷新列表(重新搜索)
                if (json.code === 0) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.settagPage = false; // 关闭窗口
                        },
                    });
                } else {
                    this.Tool.errormes(json);
                }
            });
        },
        // 导出
        exportOrder() {
            let postdata = {
                api_name: "store.store.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
                export: "yes",
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            // 判断时间条件
            if (this.searchtime !== null) {
                postdata.starttime = this.searchtime[0]
                postdata.endtime = this.searchtime[1]
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (res) => {
                if (res.code === 10005) {
                    this.Tool.errormes(res);
                } else {
                    location.href = res;
                }
                this.loading = false;
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>>.el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}
</style>
